:root {
    --clip-header: polygon(100% 0%, 0% 0%, 0% 100%, 100% 2.2em);
    --clip-header-mobile: polygon(100% 0%, 0% 0%, 0% 100%, 100% 1.6em);
    --clip-header-gradient: polygon(100% 0%, 59vw 0%, 7vw 42.8vh, 100% 90%);
}

.header-gradient.text-box {
    background-color: rgba(var(--gradient-3-color), 1);
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 2vh,
        transparent 40%
    );
}

.header-gradient {
    z-index: 30;
    width: 80vw;
    height: calc(54vw - (0.02 * 100vh));
    top: 0;

    clip-path: var(--clip-header-gradient);
    position: absolute;
    margin-top: 2em;
    right: 0;
}

.top-right {
    background: radial-gradient(
            circle at 0% 0%,
            rgba(var(--gradient-1-color), 1),
            rgba(var(--gradient-1-color), 0) 70%
        ),
        radial-gradient(
            circle at 0% 100%,
            rgba(var(--gradient-2-color), 1),
            rgba(var(--gradient-3-color), 0) 70%
        ),
        radial-gradient(
            circle at 50% 0%,
            rgba(var(--gradient-3-color), 1),
            rgba(var(--gradient-2-color), 0) 70%
        );

    background-size: 150% 150%;
    background-attachment: fixed;

    animation: bg-gradient 10s ease-in infinite;
}

.header {
    clip-path: var(--clip-header);
    z-index: 100;

    top: 0;
    margin-top: 0%;
    width: 100vw;
    height: 4em;
    position: fixed;
}

.header-left {
    top: 0;
    left: 0;

    margin: 1em;
    margin-top: 0.5em;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.header-logo {
    flex: 0 1 40px;
    width: 40px;

    margin-right: 1em;
}

.header-text {
    /*margin-top: -0.1em;*/
    flex: 0 1 auto;
    color: white;
    text-align: left;
    font-size: 1.6em;
}

@media (pointer: none), (pointer: coarse) {
    .header {
        clip-path: var(--clip-header-mobile);
        background-color: #000;

        font-size: 2em;
        height: 2.3em;

        position: fixed;
    }
    .header-left {
        /*display: inline;*/
    }
    .header-logo {
        top: 0;
        width: 50px;
        position: absolute;
        margin: 0.5em;
        margin-top: 0.35em;
    }
    .header-text {
        flex: 0 1 auto;
        color: white;
        text-align: left;
        font-size: .5em;
        /*display: none;*/
    }
}
