:root {
    --clip-first: polygon(100% 0%, 0% 0%, 0% 80%, 90% 100%);
    /* --clip-first: polygon(100% 0%, 0% 0%, 0% 46vh, 90% calc( 100vh - ( 0.0045 * 9010vw ))); */
    --clip-first-left: polygon(100% 0%, 0% 0%, 0% 100%, 20% 100%);

    --clip-first-left-mobile: polygon(100% 0%,
            0% 0%,
            0% 100%,
            100% calc(100% - 15vw));
    --clip-first-mobile: polygon(100% 0%,
            0% 0%,
            0% calc(89.5% - 1.8em),
            100% 89.5%);

    --mobile-gradient-begin: calc(100% - 20vh);
    --mobile-gradient-end: calc(100% - 10vh);
    --mobile-gradient-begin: calc(100% - 25vh);
    --mobile-gradient-end: calc(100% - 19vh);

    /*--left-column-total: calc( 100% - (0.02 * 100vh) );*/
    /*--left-column-height: calc( 0.7 * left-column-total );*/
    /*--left-column-margin: calc( 0.3 * left-column-total );*/
}

/*=========================
|  1st row in the column  |
=========================*/
.flexbox .row.first {
    clip-path: var(--clip-first);
    flex: 0 1 54vw;

    margin: 0;
    margin-bottom: -10%;
    padding: 0;

    display: flex;
    flex-direction: row;
    position: relative;
}

/*==============================
|  angled tagline div on left  |
==============================*/

.row.first .column.first {
    z-index: 27;
    flex: 1 0 10vw;
    min-width: 80vw;
    min-height: calc(100% - (0.02 * 100vh));

    clip-path: var(--clip-first-left);
    position: absolute;
    margin-top: 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row.first .column.first .intro.contents {
    flex: 1 1 30vw;
    /* width: 30%; */
    min-width: 15em;
    max-width: 20em;

    margin-top: 9%;
    margin-left: -48%;

    text-align: left;
}

.row.first .column.first #tagline {
    line-height: 7vw;
    font-size: 3em;
}

.row.first .column.first #desc {
    line-height: 155%;
    letter-spacing: 0.04em;
    font-size: 3vw;
}

.row.first .rightfloat {
    width: 50%;
    position: absolute;
    right: 6em;
    top: 42%;
    line-height: 2.3em;
    font-size: 1.6vw;
    text-align: right;
}

.row.first .rightfloat .tagline {
    margin-top: 0.5em;
    margin-left: 8em;
    text-align: left;
}

.row.first .rightalign {
    width: 20em;
    text-align: right;
}


/*=========================
|  angled image on right  |
=========================*/
.row.first .column.second {
    flex: 0 0 54vw;
    min-height: 54vw;
    /* min-width: 73vw; */
    z-index: 26;

    /* background: linear-gradient( to bottom left, #008, #0000 100%); */
    /* background: linear-gradient( to bottom left, #00f, #0000 100%); */

    position: absolute;
    right: 0;
    bottom: 0;
}

.row.first .column.second .bottom-gradient {
    display: none;
}

@media (pointer: none),
(pointer: coarse) {
    .header-gradient {
        display: none;
    }

    .flexbox .row.first {
        clip-path: none;
        flex: 0 1 calc(55vh + 50vmax);
        min-width: 100vw;
        min-height: 105vh;

        margin: 0;
        margin-top: -1em;

        flex-direction: column;
    }

    .row.first .column.first {
        clip-path: var(--clip-first-left-mobile);
        z-index: 20;
        flex: 0 0 85vw;
        min-height: 65%;
        max-height: 45vw;
        min-width: 100vw;
        max-width: 100vw;

        margin: 0;
        font-size: 1.5em;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .row.first .column.first .intro.contents {
        flex: 0 0 10vw;

        margin: 0;
        margin-top: 3vh;
        margin-bottom: 4vw;
        padding: 1em;
        padding-right: 2em;

        text-align: right;
    }

    .row.first .column.first #tagline {
        font-size: 2.6em;
    }

    .row.first .column.first #desc {
        font-size: 1.2em;
    }

    .row.first .rightfloat {
        width: 90vw;
        position: absolute;
        text-align: right;

        line-height: 1.5em;
        right: 2em;
        top: 60%;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: max(3vw, 0.8em);
        bottom: -2em;

        /* font-size: min(4vw, 1.5em); */
    }

    .row.first .rightfloat .inner {
        width: 90vw;
        padding-left: 1em;
    }

    .row.first .rightfloat .tagline {
        margin-top: 0.5em;
        margin-left: 8em;
        text-align: left;
    }

    .row.first .column.second {
        z-index: 10;

        flex: 1 1 auto;
        min-width: 100vmax;

        /*background-image: url('https://c.tenor.com/ZvOCunW56s4AAAAd/rain-pixel.gif');*/
        background-size: auto 70vmax;
        background-repeat: no-repeat;
        background-position: 60% 0%;

        position: absolute;
        top: 34vh;
        left: 0;
        /* bottom: -12vw; */
        /* 187.5 is the correct angle but it looks weird since the left side is darker so the gradient is more noticeable there: .; */
        mask-image: linear-gradient(186.8deg,
                rgba(0, 0, 0, 1) var(--mobile-gradient-begin),
                rgba(0, 212, 255, 0) var(--mobile-gradient-end));
    }
}

@media (pointer: none) and (max-width: 500px),
(pointer: coarse) and (max-width: 500px) {
    .row.first .rightfloat .inner br {
        display: none;
    }
    
    .row.first .rightfloat {
        overflow-wrap: break-word;
    }

    .row.first .rightfloat .tagline {
        margin-top: 0.5em;
        margin-left: 0;
        text-align: left;
        width: 100%;
    }
    
    .row.first .rightalign {
        width: 100%;
        text-align: right;
    }
}