:root {
    --clip-second-left: polygon(55% 10%, 0% 0%, 0% 100%, 55% 90%);
    /* --clip-second-left: polygon(55% 10%, 1.8em 0%, 7% 98.6%, 55% 90%); */
    --clip-demo: polygon(100% 6%, 0% 0%, 0% 100%, 100% 93.5%);
    --clip-demo: polygon(100% 6%, 0% 0%, 0% 100%, 100% 94%);

    --clip-second-left-mobile: polygon(100% 13vw,
            0% 0%,
            0% calc(100% - 13vw),
            100% 100%);
    --clip-demo-mobile: polygon(100% 13vw,
            0% 0%,
            0% calc(100% - 13vw),
            100% 100%);
}

/*=========================
|  2nd row in the column  |
=========================*/
.flexbox .row.second {
    flex: 0 0 58vw;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    position: relative;
}

/*==============================================
|  angled description of features on the left  |
==============================================*/
.row.second .column.first {
    z-index: 23;
    flex: 0 0 60vw;
    min-width: 100%;
    min-height: 64vw;

    clip-path: var(--clip-second-left);
    position: absolute;
    left: 0;
    margin-top: 2em;
    padding: 3em;
    padding-right: 58%;

    display: flex;
    align-items: center;
    font-size: 1.8vw;
}

.row.second .column-first {
    background-color: rgba(var(--gradient-3-color), 1);

    z-index: 23;
    flex: 0 0 60vw;
    min-width: 100%;
    min-height: 64vw;

    clip-path: var(--clip-second-left);
    position: absolute;
    left: 0;
    margin-top: 2em;
    padding: 3em;
    padding-right: 58%;

    display: flex;
    align-items: center;
    font-size: 1.8vw;
}

#demotext {
    width: 100%;
    text-align: center;
    z-index: 23;
}

/*========================================
|  layered imgs of features on the left  |
========================================*/
.demo {
    z-index: 25;
}

.row.second .button {
    position: absolute;
    font-size: 2.7vw;
    top: 50%;
    right: 5%;
}

.row.second .demo>div {
    background-color: rgba(var(--gradient-3-color), 1);
    flex: 0 0 100%;
    min-width: 27%;
    min-height: 80%;
    min-height: 48vw;

    position: absolute;
    top: 20%;
    clip-path: var(--clip-demo);
    mask-image: linear-gradient(to right, #000 20%, rgba(0, 0, 0, 0));
}

/*===================================================
|  bring layer to top & kill transparency on hover  |
===================================================*/
.row.second .demo>div:hover {
    animation: clip-fade-in 0.16s ease forwards;
}

.row.second .demo .top {
    background-image: url("../../../../../public/profile.png");
    background-size: cover;
    background-attachment: local;
    background-position: left;

    z-index: 7;
    right: 1vw;
}

.row.second .demo .middle {
    background-image: url("../../../../../public/chat.png");
    background-size: cover;
    background-attachment: local;
    background-position: 40%;

    z-index: 6;
    right: 16vw;
}

.row.second .demo .bottom {
    background-image: url("../../../../../public/threads.png");
    background-size: cover;
    background-attachment: local;
    background-position: 40%;

    z-index: 5;
    right: 30%;
}

@media (pointer: none),
(pointer: coarse) {
    body {
        overflow: hidden
    }

    .flexbox .row.second {
        flex: 0 0 calc(20vw + 60vh);
        margin-top: -19.5vh;

        flex-direction: column;
    }

    .row.second .column.first {
        clip-path: var(--clip-second-left-mobile);
        z-index: 13;
        flex: 0 0 95vw;
        min-height: 55vh;
        max-height: 75vh;
        min-width: 100vw;
        max-width: 105vw;

        position: relative;
        font-size: 1em;

        margin: 0;
        padding: 4em;

        padding-left: 0;
        padding-right: 0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    #demotext {
        padding: 0;
        font-size: min(4vw, 1.5em);
        margin-left: 0;
        width: 100vw;

        display: inline-block;
        text-align: center;
    }

    .demo {
        z-index: 12;
    }

    .row.second .button {
        /* position: absolute; */

        position: relative;
        /* font-size: 2.7vw; */
        top: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3em;
        bottom: 4em;
    }

    .row.second .demo>div {
        clip-path: var(--clip-demo-mobile);
        flex: 1 0 55vw;
        /* min-height: 50vw;
        max-height: 65vw;
        min-width: 100vw;
        max-width: 100vw; */

        mask-image: linear-gradient(to right, #000 25%, rgba(0, 0, 0, 0));

        /* margin-top: -25vw; */

        position: relative;
        text-align: center;
    }

    .row.second .demo>div:hover {
        z-index: 8;
        opacity: 1;
        animation: clip-fade-in 0.16s ease forwards;
    }

    .row.second .demo .top {
        background-position: top;

        z-index: 4;
        right: 0%;
        opacity: 0.95;
    }

    .row.second .demo .middle {
        background-position: top;

        z-index: 5;
        background-position: 100% 40%;
        right: 0%;
        opacity: 0.95;
    }

    .row.second .demo .bottom {
        background-position: top;

        margin-top: -20vw;
        z-index: 6;
        background-position: 100% 70%;
        right: 0%;
        opacity: 0.95;
    }
}

/*===============================================================
|  animation 4 bring layer to top & kill transparency on hover  |
===============================================================*/
@keyframes clip-fade-in {
    0% {
        mask-image: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0));
    }

    10% {
        mask-image: linear-gradient(to right, #000 10%, rgba(0, 0, 0, 0));
    }

    20% {
        mask-image: linear-gradient(to right, #000 20%, rgba(0, 0, 0, 0));
    }

    30% {
        mask-image: linear-gradient(to right, #000 30%, rgba(0, 0, 0, 0));
    }

    40% {
        mask-image: linear-gradient(to right, #000 40%, rgba(0, 0, 0, 0));
    }

    50% {
        mask-image: linear-gradient(to right, #000 50%, rgba(0, 0, 0, 0));
    }

    60% {
        mask-image: linear-gradient(to right, #000 60%, rgba(0, 0, 0, 0));
    }

    70% {
        mask-image: linear-gradient(to right, #000 70%, rgba(0, 0, 0, 0));
    }

    80% {
        mask-image: linear-gradient(to right, #000 80%, rgba(0, 0, 0, 0));
    }

    90% {
        mask-image: linear-gradient(to right, #000 90%, rgba(0, 0, 0, 0));
    }

    100% {
        z-index: 8;
        mask-image: none;
    }
}