:root {
    --clip-button-separator: polygon(95% 0%, 75% 0%, 5% 100%, 25% 100%);
}

.header-buttons {
    position: absolute;
    width: 60%;
    top: -25%;
    right: 0.3em;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.button-separator {
    clip-path: var(--clip-button-separator);
    background-color: #fff;

    height: 3.4vh;
    min-height: 25.875px;
    width: 0.8vh;
    min-width: 6px;
}

@media (pointer: none), (pointer: coarse) {
    .header-buttons {
        top: -15%;
    }
} ;
