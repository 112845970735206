:root {
    --clip-ribbons: polygon(100% 0%, 65% 0%, 0% 60vw, 0% 100%, 100% 33%);

    --ribbon-path: path('M -40 90 C 10 20, 55 130, 99 -5 S 80 100, -40 80');
    --ribbon-path2: path('M -40 90 C 20 10, 70 120, 102 -5 S 45 120, -50 90');
    --ribbon-path3: path('M -50 120 C 40 0, 85 90, 104 -5 S 105 88, -60 80');
    --ribbon-path4: path('M -190 200 C 40 0, 92 81, 100 -5 S 20 120, -50 95');
    --ribbon-path5: path('M -40 90 C 5 60, 92 50, 102 -20 S 104 97, -120 120');
    --ribbon-path6: path('M -50 80 C 5 60, 92 50, 108 -10 S 60 90, -40 90');

    --clip-ribbons-mobile: polygon(100% 5%, 0% 52.6%, 0% 70%, 100% 48%);
    --clip-ribbons-mobile: polygon(
        calc(100%) calc(50% - 95vw),
        0% 52.6%,
        0% 70%,
        100% 48%
    );
    --clip-ribbons-mobile-wide: polygon(100% 6%, 0% 54%, 0% 68%, 100% 48%);

    --second-ribbon-color: #fcb;

    --first-ribbon-color: #014;
    --second-ribbon-color: #206;
    --third-ribbon-color: #018;
    --fourth-ribbon-color: #007;
}

/* so, we need to dynamically cycle through several points in an animation,
then cycle back. */

.ribbons {
    z-index: 24;
    clip-path: var(--clip-ribbons);

    position: absolute;
    z-index: 25;
    height: 75%;
    width: 130%;
}

.ribbon {
    d: var (--ribbon-path);
}

.header-shape-gradient {
    --color-stop: rgba(0, 0, 0, 0);
    --color-bot: var(--stroke-color);
}

.ribbons-inner > path {
    stroke: var(--stroke-color);
    stroke-width: 0.1px;
    opacity: 0.4;
    z-index: 24;

    animation: move-forever-1 30s linear infinite;

    d: var(--ribbon-path);
}

@keyframes move-forever-1 {
    0% {
        d: var(--ribbon-path);
    }
    16% {
        d: var(--ribbon-path2);
    }
    32% {
        d: var(--ribbon-path3);
    }
    50% {
        d: var(--ribbon-path4);
    }
    66% {
        d: var(--ribbon-path5);
    }
    82% {
        d: var(--ribbon-path6);
    }
    100% {
        d: var(--ribbon-path);
    }
}

@keyframes move-forever-2 {
    0% {
        d: var(--ribbon-path5);
    }
    16% {
        d: var(--ribbon-path);
    }
    32% {
        d: var(--ribbon-path4);
    }
    50% {
        d: var(--ribbon-path6);
    }
    66% {
        d: var(--ribbon-path3);
    }
    82% {
        d: var(--ribbon-path2);
    }
    100% {
        d: var(--ribbon-path5);
    }
}

@keyframes move-forever-3 {
    0% {
        d: var(--ribbon-path);
    }
    16% {
        d: var(--ribbon-path2);
    }
    32% {
        d: var(--ribbon-path3);
    }
    50% {
        d: var(--ribbon-path4);
    }
    66% {
        d: var(--ribbon-path5);
    }
    82% {
        d: var(--ribbon-path6);
    }
    100% {
        d: var(--ribbon-path);
    }
}

@media (pointer: none), (pointer: coarse) {
    .ribbons {
        /* background-color: black; */
        clip-path: var(--clip-ribbons-mobile);
        width: 110%;
        height: 83%;
        margin-top: -20vh;
    }
    .ribbons-inner > path {
        opacity: 0.3;
    }
    .ribbons-overlay {
        display: none;
        height: 0%;
        width: 0%;
        z-index: -1;
    }
}

/* @media (pointer: none), (pointer: coarse) and (min-width: 690px) {
    .ribbons { clip-path: var(--clip-ribbons-mobile-wide) }
} */
