:root {
    --box-content-color: #fff;

    /* --gradient-1-color: 238, 119, 82;
    --gradient-1-color: 255,138,53; */
    --gradient-1-color: 255, 86, 0;
    --gradient-1: radial-gradient(circle at 30% 40%,
            rgba(var(--gradient-1-color), 1),
            rgba(var(--gradient-2-color), 0) 35%);

    /* --gradient-2-color: 235, 137, 175;
    --gradient-2-color: 255, 84, 150; */
    --gradient-2-color: 255, 36, 109;
    --gradient-2: radial-gradient(circle at 50% 80%,
            rgba(var(--gradient-2-color), 1),
            rgba(var(--gradient-1-color), 0) 45%);

    --gradient-3-color: 0, 28, 76;
    --gradient-3: radial-gradient(circle at 70% 40%,
            rgba(var(--gradient-3-color), 1),
            rgba(var(--gradient-3-color), 0) 50%);
}

body {
    overflow-x: hidden
}

.header.text-box,
.row .column.text-box {
    background-color: rgba(var(--gradient-3-color), 1);
}

.text-box {
    color: var(--box-content-color);
    /* opacity: 0; */
}

.inner {
    color: var(--box-content-color)
}

.landing-page {
    width: 100vw;
    overflow-x: hidden;

    /* background-image: url('https://www.blueowl.com/wp-content/uploads/2021/07/City-Image--scaled.jpg'); */
    background: linear-gradient(0deg, rgb(8, 56, 140) 0%, rgba(0, 28, 76, 1) 50%, rgba(0, 0, 0, 1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 0%;
    background-attachment: fixed;
    /*background-color: #ff7000;*/
    position: relative;
}

@media (pointer: none),
(pointer: coarse) {
    .landing-page {
        width: 100%;
        height: 100%;
        overflow-x: hidden;

        margin: 0;
        padding: 0;
    }
}