html,
body {
    overscroll-behavior-x: none;
}

.App {
    text-align: center;
}

.rmdp-border {
    border: 0px;
}

.baam::-webkit-scrollbar {
    width: 13px;
}

.boop {
    background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 5%,
            rgba(255, 255, 255, 1) 95%);
}

.baam::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 1px 1px 1px rgba(0.3, 0.3, 0.3, 0);
    border-radius: 10px;
}

.baam::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.textExp {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
        Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-weight: 300;
}

.ASLightGrey-background {
    background-color: #ebebeb;
}

.HSBrightGrey-background {
    background-color: #eeeeee;
}

.HSDarkCharcoal-background {
    background-color: #d3d3d3;
}

.ASLightGrey-background {
    background-color: #ebebeb;
}

.poop:hover {
    background-color: lavender;
}

.clipProfilePoly {
    clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 70% 100%);
}

.clipProfilePoly2 {
    clip-path: polygon(70% 0%, 0% 0%, 0% 100%, 100% 100%);
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.connectionStatus {
    padding-top: 20px;
    margin-left: 5%;
    font-size: 1.5em;
}

.subscriber {
    margin-left: 10%;
}

.publisher {
    float: right;
    margin-right: 10%;
}

/*.OTPublisherContainer {*/
/*  width: 70vh !important;*/
/*  height: 30vh !important;*/
/*}*/
.el:before {
    content: '';
    z-index: -10;
    position: absolute;
    mask-image: radial-gradient(farthest-corner at 90% 5%,
            rgba(0, 0, 0, 1) 20%,
            rgba(255, 255, 255, 0) 50%);
    background-image: url(https://lp-cms-production.imgix.net/2019-06/101686077%20.jpg?auto=format&fit=crop&ixlib=react-8.6.4&h=520&w=1312&q=75&dpr=1);
}

/*-webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 1)), to(rgba(0, 0, 0, .5)))*/
/*.el div {*/

/*}*/

/*.el p {*/

/*}*/

.Avatar {
    -webkit-mask-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 1)),
            to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    clip-path: circle(30px at center);
    max-width: 60px;
    max-height: 60px;
    width: auto;
    height: auto;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #c2c0c2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@media (max-aspect-ratio: 4/3) {
    .App-header {
        min-height: 100dvh;
    }

    .openTokVideoElementSelectedFullscreen {
        height: 82dvh !important;
    }
}

.App-link {
    color: #61dafb;
}

.edit_button_container {
    position: absolute;
    top: 15%;
    right: 97.3%;
    text-align: right;
}

/* added Willow stuff */

.second-position {
    position: absolute;
}

.position-setter {
    position: relative;
    /* padding-left: 1.7%; */
    padding: 0% 1.7%;
}

.edit_close_button {
    background-color: #5a5a5a;
    color: #fafafa;
    border-radius: 50%;
    border: none;
}

.grow {
    transition: all 0.2s ease-in-out;
}

.grow:hover {
    transform: scale(1.1);
    z-index: 8001 !important;
}

.recolor-item {
    transition: all 0.1s ease-in-out;
}

.imageBarContainerDiv {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 230px;
    height: 300px;
}

.fixImageOpacity {
    opacity: 1.0 !important;
    max-width: 100px;
    max-height: 100px;
    display: block;
}

.recolor-item:hover {
    background-color: #5a5a5a;
}

.highlight {
    transition: all 0.2s ease-in-out;
}

.highlight:hover {
    border-radius: 20px 0% 20px 0%;
    box-shadow: 0px 0px 5px 8px rgba(255, 255, 255, 0.1);
}

.fade-in-modal {
    animation: fadeIn 4s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.invisible-modal {
    opacity: 0;
}


.openTokVideoElementNormal {
    width: 28% !important;
    height: 45% !important;
    z-index: 8000 !important;
    display: block !important;
}

.openTokVideoElementSelected {
    width: 60% !important;
    height: 70% !important;
    z-index: 8000 !important;
    display: block !important;
}

.openTokVideoElementHidden {
    display: none !important;
}

.openTokVideoElementNormalFullscreen {
    width: 40vw !important;
    height: 40% !important;
    z-index: 8000 !important;
    display: block !important;
}

.openTokVideoElementSelectedFullscreen {
    width: 90vw !important;
    height: 82vh !important;
    z-index: 8000 !important;
    display: block !important;
}

.openTokVideoElementLabel {
    position: absolute;
    left: 2%;
    top: 0%;
}

@keyframes example {
    0% {
        background-color: red;
        left: 0px;
        top: 0px;
    }

    25% {
        background-color: yellow;
        left: 200px;
        top: 0px;
    }

    50% {
        background-color: blue;
        left: 200px;
        top: 200px;
    }

    75% {
        background-color: green;
        left: 0px;
        top: 200px;
    }

    100% {
        background-color: red;
        left: 0px;
        top: 0px;
    }
}

.outerInputContainerDiv1 {
    position: absolute;
    border-radius: 20px 2px 20px 2px;
    top: calc(100% - 400px);
    width: calc(100% - 16px);
    max-height: 350px;
    min-height: 350px;
    display: flex;
    background-color: #0F0F0FFF;
    transition: all 0.2s ease-in-out;
    flex-wrap: wrap;
    overflow-y: auto;
}

.outerInputContainerDiv2 {
    position: absolute;
    border-radius: 20px 2px 20px 2px;
    top: calc(100% - 65px);
    width: calc(100% - 16px);
    min-height: 50px;
    display: flex;
    background-color: #0F0F0FFF;
    transition: all 0.2s ease-in-out;

}

.outerInputContainerImageButton {
    width: 230px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerInputDiv {
    position: absolute;
    /* border-radius: 20px 2px 20px 2px; */
    top: calc(100% - 65px);
    width: calc(100% - 16px);
    min-height: 50px;
    max-height: 65px;
    display: flex;
    /* background-color: #0F0F0F; */
}

.leftInputDiv {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.middleInputDiv {
    flex: 1;
    overflow-x: auto;
    display: flex;
    align-items: center;
}

.mainInputDiv {}

.rightInputDiv {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reply-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    float: none;
    cursor: pointer;
}

.img-preview {
    top: 37%;
    size: 300px;
}

.absolute-div {
    position: absolute;
}

.cursor-handle {
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.scroller::-webkit-scrollbar {
    width: 10px;
}

.scroller::-webkit-scrollbar-track-piece {
    background: white;
    border: solid 1px black;
}

.scroller::-webkit-scrollbar-thumb {
    background: black;
}

.scroller::-webkit-scrollbar-button:end:increment {
    height: 50%;
    display: block;
    background: transparent;
}

/* added Willow stuff */

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}