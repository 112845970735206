:root {
    --clip-last: polygon(100% 9vw, 0% 0%, 0% 100%, 100% 100%);
    --clip-last: polygon(100% 0%, 0% 11vw, 0% 100%, 100% 100%);

    --clip-last-mobile: polygon(100% 13vw, 0% 0%, 0% 100%, 100% 100%);
}

/*==========================
|  last row in the column  |
==========================*/
.flexbox .row.last {
    flex: 1 1 auto;
    margin-top: -2.5vw;

    padding: 0;

    display: flex;
}

/*==============================
|  angled footer with sitemap  |
==============================*/
.row.last .column.first {
    clip-path: var(--clip-last);
    flex: 1 1 35vw;
    width: 100%;
    min-height: 25vmax;
    max-height: 90vmax;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: calc(0.6vw + 2vw + 1em);
    padding-top: 10%;
    padding-bottom: 5%;
}
.row.last .column.first.text-box-old {
    background: #ff08;
    position: absolute;
    left: 0;
    z-index: 200;
    margin-top: calc(0.6vw + 2vw + 1em);
}

@media (pointer: none), (pointer: coarse) {
    .flexbox .row.last {
        flex: 1 1 auto;
        min-width: 100vw;
        max-width: 100vw;

        display: flex;
        flex-direction: column;
    }

    .row.last .column.first {
        clip-path: var(--clip-last-mobile);
        flex: 0 1 80vw;
        min-height: 45vw;
        max-height: 70vh;

        margin: 0;
        margin-top: -8.5vw;
        padding: 0;
        padding-top: 10vmax;

        justify-content: center;
    }
}
