.mobileMockModal {
    --mobilenav-height: calc(0px + 10px);
    position: absolute;
    bottom: var(--mobilenav-height);
    height: calc(100dvh - var(--mobilenav-height));

    width: 100vw;
    background: #000;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
}

.fanContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100dvh;
    width: 100vw;
    z-index: -1;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
}

.animateMockModal {
    visibility: visible;
    opacity: 0.6;
}

#fanmenu {
    --bottom-gap: -20px;
    --mobilenav-height: calc(0px + 10px);
    width: 50px;
    height: 1.6em;
    position: absolute;
    bottom: 0;

    /* left: 0; */
    right: 25px;
    margin: 0;
    margin-bottom: 25px;

    list-style: none;
    z-index: 2006;
}

.animateFanMenuOpacity {
    visibility: visible;
    opacity: 1;
    z-index: 2000;
}

.fan-menu-item {
    width: 50px;
    height: 50px;
    line-height: 5px;
    margin: 0px -25px 0 -25px;

    border-radius: 50%;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    opacity: 0;
    transition: opacity 500ms;
    z-index: -2;
    transition: .5s;
}

.fan3 {
    bottom: 35px;
}

.fan4 {
    bottom: 85px;
}

.fan5 {
    bottom: 135px;
}

.fan6 {
    bottom: 185px;
}

.fan7 {
    bottom: 235px;
}

.doAnimation4 {
    transform: rotate(-22.5deg) translateY(-220px) rotate(22.5deg);
    opacity: 1;
    transition-delay: 0.4s;
}

.doAnimation3 {
    transform: rotate(0deg) translateY(-220px) rotate(0deg);
    opacity: 1;
    transition-delay: 0.3s;
}

.doAnimation5 {
    transform: rotate(-45deg) translateY(-220px) rotate(45deg);
    opacity: 1;
    transition-delay: 0.2s;
}

.doAnimation6 {
    transform: rotate(-67.5deg) translateY(-220px) rotate(67.5deg);
    opacity: 1;
    transition-delay: 0.1s;
}

.doAnimation7 {
    transform: rotate(-90deg) translateY(-220px) rotate(90deg);
    opacity: 1;
    transition-delay: 0s;
}